.scroll-to-top {
    position: fixed;
    bottom: 75px;
    right: 20px;
    transition: all .2s ease-in-out;
    z-index: 999;
}

#return-to-top {
    background: rgba(0, 0, 0, 0.7);
    width: 50px;
    height: 50px;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    border: 0;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

#return-to-top .button-top {
    color: #fff;
    margin: 0;
    position: relative;
    font-size: 2.1em;
    bottom: 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

#return-to-top:hover {
    background: rgba(0, 0, 0, 0.9);
}

#return-to-top:hover .button-top {
    color: #fff;
    bottom: 5px;
}

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-out;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in;
}
