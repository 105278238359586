:root{
    --primary : #505f4e;
    --secondary : #e4e4e2;
}
.login-component{
    width: 420px;
}

.cursor-pointer {
    cursor: pointer;
}

.truncate-table-cell {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 0;
}
