.tableCSS {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.dashboardTotalDataCSS {
    /*borderStyle: "solid", borderWidth: 2, borderColor: "#e9ecef", borderRadius: 4*/
    border-style: solid;
    border-width: 2px;
    border-color: #e9ecef;
    border-radius: 5px;
}
