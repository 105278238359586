.heatMapContainer{
    width: 1100px;
    margin-top: 150px;
    margin-left: auto;
    margin-right: auto;
}
.heatMapContainer > div{
    overflow-x: scroll;
}
.heatMapContainer > div > div{
    min-width: 2000px;
}
.heatMapContainer > div > div > div:nth-child(2){
    width: 100%;
    padding-left: 18px;
    padding-right: 22px;
}
.heatMapContainer > div > div > div:nth-child(2) > div:nth-child(2) > div > div{
    height: 100px !important;
}
.heatMapContainer > div > div > div:nth-child(2) > div:nth-child(2) > div > div > div{
    margin-top: 50px;
}

.heatMapContainerDashboard{
    width: 1680px;
    margin-left: auto;
    margin-right: auto;
}
.heatMapContainerDashboard > div{
    overflow-x: scroll;
}
.heatMapContainerDashboard > div > div{
    min-width: 2000px;
}
.heatMapContainerDashboard > div > div > div:nth-child(2){
    width: 100%;
    padding-left: 18px;
    padding-right: 22px;
}
.heatMapContainerDashboard > div > div > div:nth-child(2) > div:nth-child(2) > div > div{
    height: 100px !important;
}
.heatMapContainerDashboard > div > div > div:nth-child(2) > div:nth-child(2) > div > div > div{
    margin-top: 50px;
}
.heatMapTableDashboard {
    width: 1600px;
    margin-left: auto;
    margin-right: auto;
}
.heatMapTable {
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}
